<template>
  <div class="welfareSetMealDetails">
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <div class="goodDetails_box">
      <div class="good_img">
        <el-image :src="goodsDetails.commodityPictures"></el-image>
      </div>
      <div class="good_info">
          <div class="info_header">{{goodsDetails.commodityName}}</div>
          <div class="info_brand">
            品牌：<span>{{goodsDetails.commodityBrandName}}</span>
          </div>
      </div>
    </div>
    <div class="line_box"></div>
    <!-- 配送服务Distribution service -->
    <div class="distribution_service_box">
      <h1 class="header_title">配送服务：</h1>
      <div class="serve_msg_box">
        <div v-if="goodsDetails.commodityDistribution && goodsDetails.commodityDistribution.Distribution.length !== 0">包邮说明：{{goodsDetails.commodityDistribution.Distribution}}</div>
        <div v-if="goodsDetails.commodityDistribution && goodsDetails.commodityDistribution.ExcludeArea.length !== 0">不包邮说明：{{goodsDetails.commodityDistribution.ExcludeArea}}</div>
        <div>退换货：{{goodsDetails.changeorRefund}}</div>
        <div>退换条件：{{goodsDetails.returnConditions}}</div>
      </div>
    </div>
    <div class="distribution_service_box">
      <h1 class="header_title">商品详情：</h1>
      <p v-html="goodsDetails.commodityDescription"></p>
    </div>
  </div>
</template>

<script>
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb';
import GoodListCom from '@/components/welfareMallComp/GoodListCom.vue';
import { getReqGoodInfo } from "@/network/welfareMall_api";
export default {
  name: 'WelfareSetMealDetails',
  components: {
    WelfareBreadcrumb,
    GoodListCom
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '员工N选1礼包',
          to: '/welfareSetMealList'
        },
        {
          name: '礼包详情',
          to:'/welfareSetMealDetails'
        },
        {
          name: '商品详情',
        },
      ],
      pagination: {
        pageIndex: 1,
        limit: 10,
        total: 100
      },
      goodList: [],
      num: '',
      mealPrice: '',
      festivalName: '',
      mealName: '',
      id: '',  // id
      goodsDetails: {} //商品详情
    };
  },
  created(){
    if(this.$route.params.mealID){
      this.getProductDetails(this.$route.params.mealID);
    }else if(sessionStorage.getItem('welfareMall_goodId')){
      this.getProductDetails(sessionStorage.getItem('welfareMall_goodId'));
    }
    
  },
  computed:{
  },
  watch:{
  },
  methods: {
    // 获取商品详情
    async getProductDetails (id){
      var res = await getReqGoodInfo(id);
      if(res.isSuccess){
        var goodsDetails =  res.data
        JSON.parse(JSON.stringify(goodsDetails))
        goodsDetails.commodityDistribution = JSON.parse(goodsDetails.commodityDistribution)
        let excludeArea = goodsDetails.commodityDistribution.ExcludeArea
        if(excludeArea.length == 0 ){
          goodsDetails.commodityDistribution.ExcludeArea = []
        }else{
          goodsDetails.commodityDistribution.ExcludeArea = goodsDetails.commodityDistribution.ExcludeArea.join(',') 
        }
        goodsDetails.commodityPictures = goodsDetails.commodityPictures.split(';')[0]
        this.goodsDetails = goodsDetails

      }else{
        this.$message.error(res.msg);
      }

    },
    handleChange(){},
    preventPoint(){},
    addCarBtn(){},
    // 页面条数发生改变
    handleSizeChange(val){
    },
    //页面页码发生改变 
    handleCurrentChange(val){
    }
  },
};
</script>

<style scoped lang="less">
.welfareSetMealDetails{
  width: 1200px;
  margin: 0 auto;
  // 商品信息
  .goodDetails_box{
    margin-top: 24px;
    display: flex;
    .good_img{
        width: 387px;
        height: 387px;
      .el-image{
        width: 100%;
        height: 100%;
      }
    }
    // 商品info
    .good_info{
      margin-left: 24px;
      .info_header{
          width: 789px;
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
      }
      .info_brand{
        color: #333333;
        margin-top: 24px;
      }
      
    }
  }
  //分割线
  .line_box{
    border: 1px solid #ccc;
    margin: 30px 0;
  }
  // 员工可兑换。。。
  .distribution_service_box{
    // margin-top: 40px;
    .header_title{
      color: #333333;
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 700;
    }
    .serve_msg_box{
      color: #666666;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
}
</style>
